export default defineNuxtRouteMiddleware((to, from) => {
  const config = useRuntimeConfig()
  const token = useCookie('token', { path: '/', domain: config.public.COOKIE_DOMAIN })
  const nuxt = useNuxtApp()

  if (token.value) {
    if (process.server)
      return navigateTo(nuxt.$localePath('/dashboard'))
    else
      window.location.replace(nuxt.$localePath('dashboard'))
  }
})
